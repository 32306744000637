import React, { useEffect } from "react";

const NewTicker = ({ widget }) => {
  useEffect(() => {
    // Set body background color
    if (widget?.data?.bgColor?.includes("linear-gradient")) {
      document.body.style.background = widget?.data?.bgColor;
    } else {
      document.body.style.backgroundColor = widget?.data?.bgColor;
    }
  }, [widget?.data?.bgColor]);

  const height = window.innerHeight - (window.innerHeight * 12.5) / 100;

  return (
    <div
      style={{
        height: window.innerHeight + "px",
        overflow: "hidden",
        position: "relative",
        backgroundColor: widget?.data?.bgColor || "#ffffff", // Default to white background
      }}
    >
      <div class="marquee-container">
        <div class="Marquee">
          <div
            class="Marquee-content"
            style={{
              "-webkit-animation": `marquee-${widget.data.direction} ${
                1000 / widget.data.speed
              }s linear infinite running`,
              "-moz-animation": `marquee-${widget.data.direction} ${
                1000 / widget.data.speed
              }s linear infinite running`,
              "-o-animation": `marquee-${widget.data.direction} ${
                1000 / widget.data.speed
              }s linear infinite running`,
              "-ms-animation": `marquee-${widget.data.direction} ${
                1000 / widget.data.speed
              }s linear infinite running`,
              animation: `marquee-${widget.data.direction} ${
                1000 / widget.data.speed
              }s linear infinite running`,
            }}
          >
            <div
              class="Marquee-tag"
              style={{
                fontSize: height * 1 + "px",
                color: widget?.data?.color || "#000000", // Default to black text
                fontWeight: widget?.data?.isBold ? "bold" : "normal",
                fontStyle: widget?.data?.isItalic ? "italic" : "normal",
                fontFamily: widget?.data?.fontFamily || "sans-serif",
              }}
            >
              {widget?.data?.text || "Default scrolling text"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTicker;
