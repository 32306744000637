import React from "react";
import OldTicker from "./OldTicker";
import NewTicker from "./NewTicker";

const Ticker = ({ widget }) => {
  return (
    <>
      {widget.data.isModern === "true" ? (
        <NewTicker widget={widget} />
      ) : (
        <OldTicker widget={widget} />
      )}
    </>
  );
};

export default Ticker;
