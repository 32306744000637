import React, { useEffect } from "react";

const OldTicker = ({ widget }) => {
  useEffect(() => {
    // set body background color
    if (widget?.data?.bgColor?.includes("linear-gradient")) {
      document.body.style.background = widget?.data?.bgColor;
    } else {
      document.body.style.backgroundColor = widget?.data?.bgColor;
    }
  }, [widget?.data?.bgColor]);

  const height = window.innerHeight - (window.innerHeight * 12.5) / 100;

  return (
    <div
      style={{
        height: window.innerHeight + "px",
      }}
    >
      <marquee
        scrollamount={widget.data.speed}
        direction={widget.data.direction}
        style={{
          height: window.innerHeight + "px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: height + "px",
            width: "100%",
            fontFamily: widget.data.fontFamily || "sans-serif",
          }}
        >
          <div
            style={{
              fontSize: height + "px",
              color: widget.data.color,
              fontWeight: widget.data.isBold ? "bold" : "normal",
              fontStyle: widget.data.isItalic ? "italic" : "normal",
            }}
          >
            {widget.data.text}
          </div>
        </div>
      </marquee>
    </div>
  );
};

export default OldTicker;
